<script setup>
/* eslint vuejs-accessibility/anchor-has-content: */
import { ref } from 'vue';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  activeColor: {
    type: String,
    default: 'primary',
  },
  indicatorColor: {
    type: String,
    default: 'info',
  },
  inactiveColor: {
    type: String,
    default: 'grey',
  },
  iconColor: {
    type: String,
    default: 'primary',
  },
});
const tabs = ref([
  { slug: 'tech', label: 'caratteristiche' },
  { slug: 'docs', label: 'schede informative' },
]);
const enabledTabs = ref([]);
const activeTab = ref('');
if (props.product.snippet) enabledTabs.value.push('tech');
if (props.product.docs && props.product.docs.length) enabledTabs.value.push('docs');
if (enabledTabs.value.length) [activeTab.value] = enabledTabs.value;
</script>

<template>
  <div class="product-info">
    <q-tabs
      v-if="enabledTabs.length"
      v-model="activeTab"
      align="left"
      :active-color="activeColor"
      :indicator-color="indicatorColor"
      :class="`text-${inactiveColor}`"
    >
      <q-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :name="tab.slug"
        :label="tab.label"
        :disable="!enabledTabs.includes(tab.slug)"
      >
      </q-tab>
    </q-tabs>
    <q-separator/>
    <q-tab-panels v-model="activeTab" animated vertical>
      <q-tab-panel name="tech" class="info-tab tech">
        <div class="tech-features" v-html="product.snippet"></div>
      </q-tab-panel>
      <q-tab-panel name="docs" class="info-tab docs">
        <table class="docs-list">
          <tr
            v-for="(doc, index) in product.docs"
            :key="index"
          >
            <td class="download-btn">
              <a :href="doc.link" :download="doc.name" target="_blank">
                <q-btn round>
                  <q-icon name="download" :color="iconColor"/>
                </q-btn>
              </a>
            </td>
            <td class="doc-name">{{ doc.title }}</td>
          </tr>
        </table>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<style lang="scss">
@import '~quasar-variables';

.product-info {
  .info-tab {
    padding: 1rem .5rem;
    .tech-features { font-size: 1.1rem; padding-left: 1rem }
    .docs-list {
      .download-btn, .doc-name { padding: 1rem .5rem 0 }
      .doc-name { font-size: 1.1rem }
    }
  }
}
</style>
