<script setup>
/* eslint import/no-unresolved: */
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import { Notify } from 'quasar';
import PageLayout from '@/components/layouts/page-layout';
import SiteHeader from '@/components/widgets/site-header';
import SidebarBrands from '@/components/widgets/sidebar-brands';
import ProductInfo from './product-info';

const store = useStore();
const route = useRoute();

const product = ref({});
const loading = ref(true);
const loadSettings = {
  loadingMessage: 'loading product...',
  height: '70vh',
};
const loadData = (to) => {
  const slug = to ? to.params.slug : route.params.slug;
  if (slug) {
    // loading.value = true;
    store.dispatch('getProduct', slug)
      .then((response) => {
        product.value = response;
        loading.value = false;
      })
      .catch((error) => {
        Notify.create({ type: 'app-error', message: error.message });
        loading.value = false;
      });
  }
};
onBeforeRouteUpdate(async (to, from, next) => {
  await loadData(to);
  return next();
});
loadData();
</script>

<template>
  <SiteHeader />
  <PageLayout
    :loading="loading"
    :load-settings="loadSettings"
    sidebar-right
  >
    <template #mainContent>
      <section class="header q-mt-md">
        <div class="product-image">
          <img
            alt=""
            :src="product.image"
          />
        </div>
        <div class="product-descr">
          <div class="product-name">
            {{ product.title }}
          </div>
          <div class="product-excerpt q-mt-md">
            {{ product.excerpt }}
          </div>
        </div>
      </section>
      <section class="body">
        <ProductInfo
          :product="product"
          active-color="black"
          indicator-color="logo-red"
          inactive-color="blue-grey-5"
          icon-color="logo-red"
        />
      </section>
    </template>
    <template #rightSidebar>
      <SidebarBrands />
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import '~quasar-variables';

$header-breakpoint: 600px;
.header {
  display: grid;
  @media (min-width: $header-breakpoint) { grid-template-columns: 1fr 2fr; }
  .product-image {
    padding: .5rem;
    text-align: center;
    img { max-width: 400px; width: 100%; height: auto; }
  }
  .product-descr {
    padding: .5rem;
    .product-name {
      @media (min-width: $header-breakpoint) { margin-top: 1rem; }
      font-size: 1.5rem;
      color: $blue-grey-8;
    }
    .product-excerpt {
      font-size: 1.1rem;
      color: $blue-grey-8;
      text-align: justify;
    }
  }
}
</style>
